<template>
	<ej-page>
		<ej-mobile-header>
			<ej-toolbar>
				<ej-buttons>
					<ej-back-button></ej-back-button>
				</ej-buttons>

				<ej-title>{{ $route.meta.title }}</ej-title>
			</ej-toolbar>
		</ej-mobile-header>

		<ej-content>
			<ej-breadcrumb>
				<li class="breadcrumb-item">
					<router-link :to="{name: 'automessages'}">Mensagens Programadas</router-link>
				</li>
				<li class="breadcrumb-item">
					{{ $route.meta.title }}
				</li>
			</ej-breadcrumb>

            <photo-swipe
                ref="pswp"
                :items="automessage.attachments"
                :options="{
                    closeOnScroll: false
                }"
            ></photo-swipe>

			<main class="content">
				<div class="container-fluid" v-if="spinner">
					<div class="row d-flex justify-content-center">
						<div class="default-spinner">
							<div class="default-spinner-inner">
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>

				<div class="container-fluid" v-else>
					<div class="row">
						<div class="col">
							<h5>Informações da Mensagem Programada</h5>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-lg-1 col-12">
							<label>Id</label>
							<input
								v-model.trim="automessage.id"
								class="form-control"
								disabled="disabled"
							/>
						</div>

						<div class="form-group col-lg-4 col-12">
							<label>Nome<ej-asterisk></ej-asterisk></label>
							<input
								v-model.trim="automessage.name"
								class="form-control"
							/>
						</div>

                        <div class="form-group col-lg-1 col-12">
                            <label>Intervalo<ej-asterisk></ej-asterisk></label>
                            <input
                                v-model.trim="automessage.interval"
                                class="form-control"
                                v-uppercase
                            />
                        </div>

                        <div class="form-group col-lg-1 col-12">
                            <label>Unidade<ej-asterisk></ej-asterisk></label>
                            <ej-select
                                v-model.trim="unit"
                                :options="units"
                                track-by="value"
                                label="name"
                                placeholder="Buscar"
                            ></ej-select>
                        </div>

                        <div class="form-group col-lg-2 col-12">
                            <label>A partir da<ej-asterisk></ej-asterisk></label>
                            <ej-select
                                v-model.trim="trigger"
                                :options="triggers"
                                track-by="value"
                                label="name"
                                placeholder="Buscar"
                            ></ej-select>
                        </div>

                        <div class="form-group col-lg-4 col-12">
                            <label>Texto<ej-asterisk></ej-asterisk></label>
                            <textarea
                                v-model.trim="automessage.text"
                                class="form-control"
                                rows="5"
                            />
                        </div>

                        <div class="form-group col-lg-2 col-12">
                            <label>Linha<ej-asterisk/></label>
                            <ej-select
                                v-model.trim="line"
                                :options="lines"
                            ></ej-select>
                        </div>

                        <div class="form-group mr-3">
                            <label>Ativo?</label><br>
                            <ej-toggle-button
                                v-model.trim="automessage.active"
                                :labels="{checked: 'Sim', unchecked: 'Não'}"
                            />
                        </div>

<!--                        <div class="form-group col-lg-4 col-12">-->
<!--                            <label>Variáveis</label>-->
<!--                            <div>-->
<!--                                <div><strong>Nome do Cliente<font-awesome-icon v-tooltip="'Se PF, exibe o primeiro nome, por exemplo: João Luis da Silva, exibe João. Se PJ, exibe nome fantasia inteiro'" :icon="['far', 'question-circle']"></font-awesome-icon></strong>: {nome_cliente}</div>-->
<!--                                <div><strong>Contato do Pedido<font-awesome-icon v-tooltip="'Contato informado no pedido'" :icon="['far', 'question-circle']"></font-awesome-icon></strong>: {nome_contato}</div>-->
<!--                            </div>-->
<!--                        </div>-->
					</div>

                    <h5>Anexos</h5>

<!--                    <div v-if="operation == 'edit'">-->
                        <div class="row">
                            <div class="col-12">
                                <vue-dropzone
                                    ref="myVueDropzone"
                                    id="dropzone"
                                    action="/teste"
                                    :options="dropzoneOptions"
                                    @vdropzone-file-added="vdropzonefileadded"
                                ></vue-dropzone><!-- @vdropzone-success="onSuccess" -->
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div
                                class="col-xl-2 col-lg-3 col-md-6 col-12 bloco-foto"
                                :key="index"
                                v-for="(attachment, index) in automessage.attachments"
                            >
                                <!--                            <img v-if="attachment.path.toLowerCase().endsWith('.jpg')" class="img-fluid" :src="VUE_APP_API_URL + attachment.path" alt="">-->
                                <div
                                    class="foto"
                                    :style="{backgroundImage: `url(\'${attachment.src}\')`}"
                                    v-if="isImage(attachment.name)"
                                >
                                    <div class="overlay">
                                        <button @click="openPhoto(index)">
                                            <font-awesome-icon size="3x" :icon="['fas', 'search-plus']"></font-awesome-icon>
                                        </button>

                                        <button @click="deleteAttachment(index)">
                                            <font-awesome-icon size="2x" :icon="['fas', 'times-circle']"></font-awesome-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="foto d-flex justify-content-center align-items-center" style="background-color: #f7f7f7;" v-else>
                                    <h5>{{ attachment.name.split('.').pop().toUpperCase() }}</h5>

                                    <div class="overlay">
                                        <button @click="deleteAttachment(index)">
                                            <font-awesome-icon size="2x" :icon="['fas', 'times-circle']"></font-awesome-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center align-items-center">
                                    <span style="width: 100%">{{ attachment.name }}</span>
                                </div>
                            </div>
                        </div>
<!--                    </div>-->
<!--                    <div v-else style="color: #f15050">-->
<!--                        Salve a mensagem programada para adicionar anexos.-->
<!--                    </div>-->

                    <br>

                    <div class="row d-flex justify-content-center" v-if="unit && trigger">
                        <strong>Leitura:&nbsp;</strong>{{ _.capitalize(`Enviar mensagem ${automessage.interval} ${unit.name} após ${trigger.name}`) }}
                    </div>

                    <br>

					<div class="row d-flex justify-content-center">
						<ej-button
							@click="salvar"
							class="btn btn-secondary hvr-pulse"
							:disabled="saving"
						>
							<strong>Salvar  <font-awesome-icon :icon="['fas', 'cog']" spin v-if="saving"></font-awesome-icon></strong>
						</ej-button>
					</div>
				</div>
			</main>
		</ej-content>
	</ej-page>
</template>

<script>
    //Mixins
    import FetchingMixin from "@/mixins/FetchingMixin";
    import {createImage, exibeErro, getImageBase64, resizeImage, sanitize} from "@/helpers";

    //Aux
    import _ from "lodash";
    // const _ = require('lodash');

    //Dropzone
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import Swal from "sweetalert2";
    // import ContentEditable from "@/components/ContentEditable.vue";
    import PhotoSwipe from "@/components/PhotoSwipe";

    export default {
        name: 'ProductEdit',

        data() {
            return {
                loadingAutomessage: false,
                saving: false,
                operation: undefined,
                units: [
                    {value: 'minute', name: 'Minuto(s)'},
                    {value: 'day', name: 'Dia(s)'},
                    {value: 'month', name: 'Mes(es)'},
                ],
                triggers: [
                    {value: 'delivery', name: 'Data de Entrega da Cabina'},
                ],
                lines: [
                    'COLHEITADEIRAS',
                    'FROOTER',
                    'SIGNIA/REVUS',
                ],

                automessage: {
                    name: null,
                    interval: null,
                    line: null,
                    unit: null,
                    attachments: [],
                    trigger: null,
                    text: null,
                    active: true,
                },
            }
        },

        computed: {
            dropzoneOptions() {
                return {
                    progress: true,
                    addRemoveLinks: false,
                    dictDefaultMessage: '<p><strong>Clique aqui</strong> </p><p>ou</p><p><strong>arraste e solte</strong> o(s) arquivo(s)</p>',
                    dictFallbackMessage: "Seu navegador não suporta uploads de arrastar e soltar.",
                    dictFallbackText: "Por favor, use o formulário abaixo para enviar seus arquivos como antigamente.",
                    dictFileTooBig: "O arquivo é muito grande ({{filesize}}MB). Tamanho máximo permitido: {{maxFilesize}}MB.",
                    dictInvalidFileType: "Você não pode fazer upload de arquivos desse tipo.",
                    dictResponseError: "O servidor respondeu com o código {{statusCode}}.",
                    dictCancelUpload: "Cancelar envio",
                    dictCancelUploadConfirmation: "Tem certeza de que deseja cancelar este envio?",
                    dictRemoveFile: "Remover arquivo",
                    dictMaxFilesExceeded: "Você só pode fazer upload de {{maxFiles}} arquivos.",
                    //url: this.VUE_APP_API_URL + '/automessages/' + this.$route.params.id + '/attachments',
                    url: `/`,
                    withCredentials: true,
                    // chunking: true,
                    // forceChunking: true,
                    autoQueue: false,
                    autoProcessQueue: false,
                }
            },

            VUE_APP_API_URL() {
                return process.env.VUE_APP_API_URL;
            },

            unit: {
                get() {
                    const unit = _.get(this.automessage, 'unit');
                    return unit ? this.units.find((u) => u.value == unit) : null;
                },

                set(unit) {
                    this.automessage.unit = unit.value;
                }
            },

            line: {
                get() {
                    return _.get(this.automessage, 'line');
                },

                set(line) {
                    this.automessage.line = line ?? null;
                }
            },

            trigger: {
                get() {
                    const trigger = _.get(this.automessage, 'trigger');
                    return trigger ? this.triggers.find((u) => u.value == trigger) : null;
                },

                set(trigger) {
                    this.automessage.trigger = trigger.value;
                }
            },

            spinner() {
                return this.loadingAutomessage;
            },
        },

        mixins: [
            FetchingMixin
        ],

        methods: {
            isImage(name) {
                return name.toLowerCase().endsWith('.jpg') || name.toLowerCase().endsWith('.jpeg') || name.toLowerCase().endsWith('.png');
            },

            onSuccess(originalFile, response) {
                this.$refs.myVueDropzone.dropzone.removeFile(originalFile);

                this.automessage.attachments.push(response);
            },

            openPhoto(index) {
                this.$refs.pswp.openPhoto(index);
            },

            deleteAttachment(index) {
                const vm = this;

                Swal.fire({
                    title: `Deseja mesmo remover o anexo ${index}?`,
                    text: "Você não poderá reverter esta ação!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim, remover!'
                }).then((result) => {
                    if (result.value) {
                        vm.automessage.attachments.splice(index, 1);

                        // window.axios
                        //     .delete(vm.VUE_APP_API_URL + '/automessages/' + vm.$route.params.id + '/attachments/' + vm.automessage.attachments[index].id)
                        //     .then(() => {
                        //         vm.automessage.attachments.splice(index, 1);
                        //         Swal.fire(
                        //             'Removido!',
                        //             'O anexo foi removido.',
                        //             'success'
                        //         )
                        //     })
                        //     .catch((error) => {
                        //         exibeErro(error);
                        //     });
                    }
                })
            },

            async vdropzonefileadded(originalFile) {
                console.log('addedfile', originalFile);
                const vm = this;

                try {
                    if (vm.isImage(originalFile.name)) {
                        const originalBase64 = await getImageBase64(originalFile);
                        const originalImage  = await createImage(originalBase64);

                        let resized = await resizeImage(originalImage, vm.quality, 1280, 720);
                        let thumbnail = await resizeImage(originalImage);

                        vm.automessage.attachments.push({
                            w: resized ? resized.image.width : originalImage.width,
                            h: resized ? resized.image.height : originalImage.height,
                            src: resized ? resized.image.src : originalImage.src,
                            msrc: thumbnail.image.src,
                            name: originalFile.name,
                        });

                    } else {
                        let reader = new FileReader();
                        reader.onload = function(event) {
                            // event.target.result contains base64 encoded image
                            vm.automessage.attachments.push({
                                src: event.target.result,
                                name: originalFile.name,
                            });
                        };
                        reader.readAsDataURL(originalFile);
                    }

                    vm.$refs.myVueDropzone.dropzone.removeFile(originalFile);

                } catch (error) {
                    exibeErro(error);
                }

                // let vm = this;
                //
                // window.axios.post(vm.VUE_APP_API_URL + `/automessages/${vm.$route.params.id}/attachments`, {
                //     file: originalFile
                // }).then((response) => {
                //     vm.automessage.attachments.push(response.data);
                // }).catch((error) => {
                //     exibeErro(error);
                // });
                //
                // vm.$refs.myVueDropzone.dropzone.removeFile(originalFile);
            },

            salvar() {
                const vm = this;

                this.saving = true;
                switch (this.operation) {
                    case 'create':
                        this.$store.dispatch(`automessages/post`, sanitize(this.automessage))
                            .then(function() {
                                // location.replace('/automessages/'+response.data.id);
                                history.back();
                            })
                            .catch(function (error) {
                                // handle error
                                exibeErro(error);
                            })
                            .then(function () {
                                // always executed
                                vm.saving = false;
                            });
                        break;

                    case 'edit':
                        this.$store.dispatch(`automessages/patch`, {
                            id: this.automessage.id,
                            data: sanitize(this.automessage)
                        })
                            .then(function() {
                                Swal.fire(
                                    'Sucesso!',
                                    'Mensagem programada atualizada com sucesso!',
                                    'success'
                                );
                            })
                            .catch(function (error) {
                                // handle error
                                exibeErro(error);
                            })
                            .then(function () {
                                // always executed
                                vm.saving = false;
                            });
                        break;
                }
            }
        },

        mounted() {
            const vm = this;
            vm.operation = vm.$route.name === 'automessages.edit' ? 'edit' : 'create';

            if (this.operation === 'edit') {
                this.loadingAutomessage = true;
                this.$store.dispatch(`automessages/get`, vm.$route.params.id)
                    .then(function (response) {
                        vm.automessage = Object.assign({}, vm.automessage, response.data);
                    })
                    .catch(function (error) {
                        // handle error
                        exibeErro(error);
                    })
                    .then(function () {
                        // always executed
                        vm.loadingAutomessage = false;
                    });
            }
        },

        components: {
            // ContentEditable,
            PhotoSwipe,
            vueDropzone: vue2Dropzone,
        },
    }
</script>

<style scoped>
    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
        .content {
            padding: 10px 20px 20px 20px !important;
        }
    }

    .dropzone {
        padding: 15px 20px;
    }

    .vue-dropzone {
        border-radius: 10px;
        font-family: inherit;
        background-color: #f6f6f6;
    }

    .vue-dropzone:hover {
        background-color: #f1f1f1;
    }

    .dropzone p {
        margin-bottom: 0!important;
    }
</style>
